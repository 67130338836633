

.App-header {
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: var(--text-primary);
  box-sizing: content-box;
}

.App {
  color: var(--text-primary);
  background-color: var(--background);
  font-size: large;
  font-weight: bold;
  transition: all .5s;
}

.App-body{
  
}

a:hover{
  opacity: .7;

}

.myfooter{
  color: var(--text-primary);
  background-color: var(--background);
  transition: all .5s;
  text-align: center;
}

.myfooter .col{
  margin: 0 1.5rem 0 1.5rem;
}



.navbar-nav button {
 
  background-color: var(--background);
  color: var(--text-primary);
  border: none;
  font-family: 'Oxygen', sans-serif;
  font-size: 1rem;
  font-weight: 400;

}

.navbar-nav button:active {
color: var(--surface-1);
}

.navbar-nav .selectTheme{
  background-color: var(--text-primary);
  background-repeat: no-repeat;
  border: none;
  transition: all .5s;
  height: 1rem;
  width: 1rem;
  mask: var(--svg-btn-theme);
  -webkit-mask: var(--svg-btn-theme);
  mask-repeat: no-repeat;
}

.navbar-nav .selectTheme:hover {
  background-color: var(--text-secondary);
  opacity: .5;
  transform: rotate(90deg);
}



/*---------- NAVBAR -----------*/

.navbar-nav{
  flex-direction: row;
  align-items: inherit;
}
.nav-link, .nav-link:focus{
  margin-right: 1rem;
  color: var(--text-primary);
  font-family: 'Oxygen', sans-serif;
  font-size: 1rem;
  font-weight: 400;
}

.navbar-nav .nav-link.active{
  color: var(--text-primary);
}

.nav-link:hover{
  color: var(--text-secondary);
  opacity: .5;
}

.navbar-nav .show>.nav-link {
  color: var(--text-secondary);
}

.navbar-nav .dropdown-menu{
  position: absolute;
}

.nav-item.dropdown:hover .dropdown-menu, .dropdown-menu:hover {
  display: block;
}


.dropdown-menu {
  background-color: var(--background);
  color: var(--text-primary);
  border-color: var(--border-transparent);
  margin-top: 0;
  
}

.dropdown-menu[data-bs-popper]{
  margin-top: 0;
}




.dropdown-item:hover {
  background-color: var(--background-variant);
  color: var(--color-primary);
  
}

.dropdown-item.active{
  background-color: var(--border-transparent);
  color: var(--color-secondary);
  opacity: .7;
}

.dropdown-item{
  color: var(--text-primary);
  font-family: 'Oxygen', sans-serif;
  font-size: 1rem;
  font-weight: 400;
}



.svgBrand{
  filter: var(--svg-primary);
}

.svgBrand:hover{
  filter: var(--svg-primary-50);
}

/*----- BODY -------*/

.start .row{
  padding: 2vw;
}

.intro {
  max-width: calc(20rem + 20vw);
  text-align: right;
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
  opacity: 1;
  margin-top: 3vw;
}

.intro h1{
  font-size: calc(2rem + 6vw);
  color: var(--color-primary);
  line-height: calc(3rem + 3vw);
  margin-bottom: 4vw;
 
}

.intro p{
  font-size: calc(1rem + 0.25vw);
  align-self: flex-end;
  max-width: 500px;
}

.image-1{
  width: calc(10rem + 20vw);
    height: calc(10rem + 20vw);
    opacity: .6;
}

.intror{
  max-width: 250px;
    position: absolute;
    right: calc(-170px + 50vw);
}








.projectsslider{
  padding-top: 2vw;
  padding-bottom: 2vw;
  margin: 3vw 1rem 0 1rem;

}

.blockslider .slick-list{
  padding: 0 2.5vw 0 2.5vw;
}

.blockslider .slick-next, .blockslider .slick-next::before{
  color: var(--color-primary);
}

.blockslider .slick-prev, .blockslider .slick-prev::before{
  color: var(--color-primary);
}


.blockslider a{
  text-decoration: none;
  color: var(--text-primary);
}


.card{
  background-color: transparent;
  border: none;
  padding: 1rem;
}


/*  -------- PROJECT ---------  */


.projectBody{
  background-color: var(--surface-1);
  padding: 0;
  margin: 0;
  max-width: fit-content;
  
}

.projectBody .row{
  margin-right: 0;
  height: 100vh;
}

.projectInfo {
  padding: 6vw 5vw 6vw 5vw;
  overflow-y: scroll;
}

.projectInfo p, h4{
  font-size: medium;
}

h4{
  font-size: medium;
  color: var(--text-secondary);
 font-weight: 700;
}

.projectInfo div {
  margin-top: 2rem;
  
}

.projectInfo h3{
  font-size: larger;
  font-weight: 700;
}

.projectInfo h4{
 color: var(--text-secondary);
 font-weight: 700;
 
}

.projectInfo a{
  font-size: .95rem;
  color: var(--color-tertiary);
  text-decoration: none;
}

.projectDetails{
  padding: 0;
  height: 100%;
  overflow-y: scroll;
  background-color: #FAFAFA;
  text-align: center;
}

@media only screen and (max-width: 990px){

  .navbar-nav .selectTheme:hover{
    transform: rotate(0);
    opacity: 1;
  }

  .projectDetails{
    overflow-y:unset;
    height: fit-content;
  }

  .projectInfo{
    overflow-y:unset;
  }

  .projectBody .row{
    height:auto;
  }

}


.aboutText h1{
  font-size: calc(2rem + 4vw);
  color: var(--color-primary);
  line-height: .8;
  margin-bottom: 2rem;
}

.parrafo{
  font-size: medium;
}

.footertext a{
  color: var(--color-tertiary);
  text-decoration: none;
}

.footertext a:hover{
 opacity: .5;
}

.social{
  filter: var(--svg-primary);
  margin: 1.5rem;
  cursor: pointer;
}

.social:hover{
  opacity: .5;
}


.about li{
  list-style: none;
  font-weight: 300;
  font-size: medium;
}

.experience{
  border-bottom: .5px solid var(--border);
  margin-top: 1rem;
  
}

.experience div {
  align-self: center;
}

.periodo, .empresa{
  font-size: small;
}

.puesto p{
  font-size: medium;
  font-weight:700;
}
@import url('https://fonts.googleapis.com/css2?family=Overlock:ital,wght@0,700;0,900;1,700;1,900&family=Oxygen:wght@300;400;700&display=swap');

:root {
  /*COLORS*/
  --background: #FAFAFA;
  --background-variant: #D4CDD5;
  --surface-1: #F3EFF3;
  --syrface-2: #EFE8EE;
  --surface-3: #EBE1EA;
  --syrface-4: #EADFE8;
  --syrface-5: #E7DBE5;
  --border: #87758A;
  --border-transparent: rgba(135,117,138,0.25);
  --border-variant: #C1B7C2;
  --text-primary: #272527;
  --text-secondary: #5C505E;
  --accent: purple;
  --color-primary: #601755;
  --color-secondary: #3C1043;
  --color-tertiary: #5842B0;
  --svg-primary: invert(0%) sepia(22%) saturate(4711%) hue-rotate(279deg) brightness(94%) contrast(102%);
  --svg-primary-50: invert(0%) sepia(22%) saturate(4711%) hue-rotate(279deg) brightness(94%) contrast(102%) opacity(75%);
  --svg-btn-theme: url('./assets/sun-fill.svg');

}
[data-theme='dark'] {
  /*COLORS*/
  --background: #272527;
  --background-variant: #5C505E;
  --surface-1: #373136;
  --syrface-2: #393038;
  --surface-3: #3B2F39;
  --syrface-4: #3B2F39;
  --syrface-5: #3D2F3B;
  --border: #9A8B9D;
  --border-transparent: rgba(154,139,157,0.25);
  --border-variant: #5C505E;
  --text-primary: #E5E2E5;
  --text-secondary: #C1B7C2;
  --accent: darkred;
  --color-primary: #B688AF;
  --color-secondary: #A084A4;
  --color-tertiary: #B1A3E7;
  --svg-primary: invert(60%) sepia(15%) saturate(765%) hue-rotate(258deg) brightness(97%) contrast(83%);
  --svg-primary-50: invert(60%) sepia(15%) saturate(765%) hue-rotate(258deg) brightness(97%) contrast(83%) opacity(65%);
  --svg-btn-theme: url('./assets/moon-stars-fill.svg');
}

body {
  font-family: 'Oxygen', sans-serif; 
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
    background-color: var(--background);
}

.all {
  background-color: var(--background);
}

h1, h2{
  font-family: 'Overlock', cursive;
}

.h3, .h4, h3, h4 {
  font-family: 'Oxygen', sans-serif;
  font-weight: 700;
}



p, a{
  font-family: 'Oxygen', sans-serif; 
  font-weight: 100;
}


